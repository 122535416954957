import React from 'react';
import styles from './Version.module.scss';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../../util/formatDate';

function entryToElement(id, entry) {
  let content = entry.content;
  if (Array.isArray(entry.content)) {
    content = entry.content.map((child, index) => {
      if (typeof child === 'object') {
        return entryToElement(`${id}-${index}`, child);
      }
      return child;
    });
  }
  return React.createElement(
    entry.tag,
    { key: `${id}`, ...entry.attributes },
    content,
  );
}

export default function Version(props) {
  const { version, updateType, date, content } = props;
  const timestamp = new Date(date);
  const relativeDate = getRelativeTimestamp(timestamp, false);
  const absoluteDate = getAbsoluteTimestamp(timestamp, false, false);

  let displayUpdateType, displayUpdateClass;

  switch (updateType.toLowerCase().trim()) {
    case 'fix':
    case 'hotfix':
      displayUpdateType = 'Hotfix';
      displayUpdateClass = 'hotfix';
      break;
    case 'minor':
      displayUpdateType = 'Minor Update';
      displayUpdateClass = 'minor';
      break;
    case 'major':
      displayUpdateType = 'Major Update';
      displayUpdateClass = 'major';
      break;
    case 'patch':
    default:
      displayUpdateType = 'Patch';
      displayUpdateClass = 'patch';
      break;
  }

  return (
    <div className={styles.version}>
      <div className={styles.header}>
        <div className={styles.left}>
          <p className={styles.semanticVersion}>{version}</p>
          <p className={`${styles.updateType} ${styles[displayUpdateClass]}`}>
            {displayUpdateType}
          </p>
        </div>
        <div className={styles.right}>
          <p className={styles.relativeDate}>{relativeDate}</p>
          <p className={styles.absoluteDate}>({absoluteDate})</p>
        </div>
      </div>
      <div className={styles.content}>
        {content.map((entry, index) =>
          entryToElement(`${version}-${index}`, entry),
        )}
      </div>
    </div>
  );
}
