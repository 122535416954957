import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Support from '../Support/Support';
import Privacy from '../Privacy/Privacy';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import Changelog from '../Changelog/Changelog';

function SupportLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="contact" />} />
      <Route path="contact" element={<Support />} />
      <Route path="privacy" element={<Privacy />} />
      <Route
        path="privacypolicy"
        element={<Navigate to="/support/privacy" />}
      />
      <Route
        path="privacy_policy"
        element={<Navigate to="/support/privacy" />}
      />
      <Route
        path="privacy-policy"
        element={<Navigate to="/support/privacy" />}
      />
      <Route
        path="privacynotice"
        element={<Navigate to="/support/privacy" />}
      />
      <Route
        path="privacy_notice"
        element={<Navigate to="/support/privacy" />}
      />
      <Route
        path="privacy-notice"
        element={<Navigate to="/support/privacy" />}
      />
      <Route path="privacynote" element={<Navigate to="/support/privacy" />} />
      <Route path="privacy_note" element={<Navigate to="/support/privacy" />} />
      <Route path="privacy-note" element={<Navigate to="/support/privacy" />} />
      <Route path="terms" element={<TermsOfUse />} />
      <Route path="terms_of_use" element={<Navigate to="/support/terms" />} />
      <Route path="terms-of-use" element={<Navigate to="/support/terms" />} />
      <Route path="tos" element={<Navigate to="/support/terms" />} />
      <Route path="termsofservice" element={<Navigate to="/support/terms" />} />
      <Route
        path="terms_of_service"
        element={<Navigate to="/support/terms" />}
      />
      <Route
        path="terms-of-service"
        element={<Navigate to="/support/terms" />}
      />
      <Route path="tou" element={<Navigate to="/support/terms" />} />
      <Route path="changelog" element={<Changelog />} />
    </Routes>
  );
}

export default SupportLayout;
