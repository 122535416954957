import Axios from 'axios';
import { Auth } from 'aws-amplify';

async function tryToRefreshToken(config) {
  try {
    // get current session (refreshes the token automatically if necessary).
    const session = await Auth.currentAuthenticatedUser().then((user) =>
      user.getSession((error, session) => {
        if (error) {
          throw error;
        }
        return session;
      }),
    );
    const idToken = session.getIdToken().getJwtToken();

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${idToken}`,
      },
    };
  } catch (error) {
    console.warn('Could not refresh the token.', error);
  }
  return config;
}

function setBaseUrlForLocalDevelopment(config) {
  const API_MAP = new Map([
    ['account', 4000],
    ['administrator', 4001],
    ['user', 4002],
    ['score', 4003],
  ]);
  const firstCrumb = config.url
    .split('/')
    .filter((s) => s.length > 0)[0]
    .toLowerCase();

  return { ...config, baseURL: `http://localhost:${API_MAP.get(firstCrumb)}` };
}

function setGlonVersionHeaderForAccountRequests(config) {
  if (
    config.method.toLowerCase() === 'get' &&
    /^\/account\/?$/gi.test(config.url) &&
    !!process.env.REACT_APP_VERSION
  ) {
    // eslint-disable-next-line no-unused-vars
    const [_, major, minor, patch] =
      process.env.REACT_APP_VERSION.match(/(\d+)\.(\d+)\.(\d+)/i);

    config.headers['X-Glon-Version'] = `${major}.${minor}.${patch}`;
  }

  return config;
}

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 20000,
});

instance.interceptors.request.use(tryToRefreshToken);
instance.interceptors.request.use(setGlonVersionHeaderForAccountRequests);

if (
  process.env.REACT_APP_VERSION &&
  process.env.REACT_APP_VERSION.substring(0, 5) === 'local'
) {
  instance.interceptors.request.use(setBaseUrlForLocalDevelopment);
}

export default instance;
