import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import styles from './GoalEntry.module.scss';
import ProgressBar from '../../../../Common/ProgressBar/ProgressBar';
import GoalProgressChart from '../../../../Common/TreeEditor/Nodes/GoalNode/GoalProgressChart/GoalProgressChart';
import { AdministratorLogMeasurementDialog } from '../../../../Common/LogMeasurementDialog/LogMeasurementDialog';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../../../util/formatDate';

function getDurationProgress(start, end, now) {
  if (now > end) {
    return 1;
  }
  if (now < start) {
    return 0;
  }
  return (now - start) / (end - start);
}

export default function GoalEntry(props) {
  const [isLogMeasurementDialogOpen, setIsLogMeasurementDialogOpen] =
    React.useState(false);
  const { goal, reload } = props;

  const {
    id,
    label,
    isTeamGoal,
    description,
    startValue,
    endValue,
    startTime,
    endTime,
    goalMeasures,
  } = goal;

  const currentValue =
    goalMeasures.length > 0
      ? goalMeasures[goalMeasures.length - 1].value
      : startValue;

  const startTimeMs = new Date(startTime).getTime();
  const endTimeMs = new Date(endTime).getTime();
  const nowMs = new Date().getTime();
  const timeProgress = getDurationProgress(startTimeMs, endTimeMs, nowMs);
  const goalProgress = (currentValue - startValue) / (endValue - startValue);
  const goalProgressDesiredValue = Math.floor(
    timeProgress * (endValue - startValue) + startValue,
  );
  const goalProgressDesired =
    (goalProgressDesiredValue - startValue) / (endValue - startValue);

  return (
    <div className={styles.goalEntry}>
      <AdministratorLogMeasurementDialog
        goal={goal}
        isOpen={isLogMeasurementDialogOpen}
        close={() => setIsLogMeasurementDialogOpen(false)}
        reload={reload}
      />
      <div className={styles.innerWrapper}>
        <div className={`${styles.topRow}`}>
          <Link to={id}>
            <p className={styles.title}>{label}</p>
          </Link>
        </div>
        <div className={styles.mainRow}>
          <div className={styles.stack}>
            {(description && (
              <p className={styles.description}>{description}</p>
            )) || <p className={styles.noData}>No description for this goal</p>}
          </div>
          <div className={styles.stack}>
            <Tooltip
              title={`Current: ${currentValue}, optimal: ${goalProgressDesiredValue}`}
              className={styles.stack}
            >
              <div className={styles.charts}>
                <p className={styles.label}>Goal Progress:</p>
                <ProgressBar
                  value={goalProgress}
                  color="linear-gradient(90deg, rgb(158 212 131), rgb(146 234 178))"
                  trailingDot
                  trailingDotColor="rgb(105 184 105)"
                />

                <ProgressBar
                  value={goalProgressDesired}
                  color="linear-gradient(90deg, rgb(127 151 230), rgb(176 221 254))"
                  trailingDot
                  trailingDotColor="rgb(103 155 219)"
                />
                <div className={styles.row}>
                  <p className={styles.value}>{startValue}</p>
                  <p className={styles.value}>{endValue}</p>
                </div>
              </div>
            </Tooltip>
            <p className={styles.label}>Time:</p>
            <ProgressBar
              value={timeProgress}
              color="linear-gradient(90deg, rgb(212 141 186), rgb(214 183 255)"
              trailingDot
              trailingDotColor="rgb(170 124 232)"
            />
            <div className={styles.row}>
              <p className={styles.value}>
                {getAbsoluteTimestamp(new Date(startTime))}
              </p>
              <p className={styles.value}>
                {getAbsoluteTimestamp(new Date(endTime))}
              </p>
            </div>
          </div>
          <div className={styles.stack}>
            <GoalProgressChart
              label={label}
              startValue={startValue}
              endValue={endValue}
              startTime={startTime}
              endTime={endTime}
              goalMeasures={goalMeasures}
            />
          </div>
          <div className={styles.stack}>
            <p className={styles.label}>Team{goal.teams.length > 0 && 's'}:</p>
            {(goal.teams.length === 0 && (
              <div className={styles.row}>
                <p className={styles.noData}>Not assigned</p>
                <Button
                  size="small"
                  startIcon={<GroupAddOutlinedIcon />}
                  component={Link}
                  to={'/administrator/goals/hierarchy'}
                >
                  Assign Team
                </Button>
              </div>
            )) || (
              <div className={styles.row}>
                <p className={styles.teams}>
                  {goal.teams.map((t, i, a) => (
                    <React.Fragment key={`goal-${id}${t.id}`}>
                      <Link to={`/administrator/teams/${t.id}`}>
                        <span className={styles.teamName}>{t.label}</span>
                      </Link>
                      {i !== a.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </p>
                {!isTeamGoal && (
                  <Button
                    // variant="outlined"
                    size="small"
                    startIcon={<GroupAddOutlinedIcon />}
                    component={Link}
                    to={'/administrator/goals/hierarchy'}
                  >
                    Edit
                  </Button>
                )}
              </div>
            )}
            <p className={styles.label}>Latest measurement:</p>
            {(goalMeasures.length === 0 && (
              <p className={styles.noData}>Not recorded</p>
            )) || (
              <p className={styles.value}>
                {goalMeasures[goalMeasures.length - 1].value}{' '}
                <span className={styles.valueSecondary}>
                  {getAbsoluteTimestamp(
                    new Date(goalMeasures[goalMeasures.length - 1].time),
                  )}
                </span>
                <span className={styles.valueSecondary}>
                  (
                  {getRelativeTimestamp(
                    new Date(goalMeasures[goalMeasures.length - 1].time),
                  )}
                  )
                </span>
              </p>
            )}
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsLogMeasurementDialogOpen(true)}
              startIcon={<AddchartOutlinedIcon />}
            >
              Log Measurement
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
