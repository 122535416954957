import React from 'react';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ScoreDisplay from '../../../Common/ScoreDisplay/ScoreDisplay';

export default function Teams() {
  const { data, error, isLoading } = useApiData({
    path: '/administrator/teams',
    params: {
      includeTeamMemberCounts: true,
      includeGoalCounts: true,
    },
  });
  if (isLoading) {
    return <Loading message="Fetching teams..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const formattedData = data.map((t) => ({
    ...t,
    performanceScore: (
      <ScoreDisplay
        subjectType="team"
        scoreType="performance"
        teamId={t.id}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
    processAdoptionScore: (
      <ScoreDisplay
        subjectType="team"
        scoreType="processAdoption"
        teamId={t.id}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
  }));

  return (
    <Table
      rows={formattedData}
      topButtons={[
        {
          label: 'Create Team',
          variant: 'contained',
          startIcon: <GroupAddIcon />,
          to: '/administrator/teams/create',
        },
      ]}
      linkFunction={(id) => id}
      rowLinks={{
        label: {
          label: 'View Team',
          to: (id) => id,
        },
      }}
      title="Teams"
      columns={[
        {
          id: 'label',
          label: 'Label',
          numeric: false,
        },
        {
          id: 'teamMemberCount',
          label: 'Members',
          numeric: true,
        },
        {
          id: 'goalCount',
          label: 'Goals',
          numeric: true,
        },
        {
          id: 'performanceScore',
          label: 'Performance',
          component: true,
        },
        {
          id: 'processAdoptionScore',
          label: 'Process Adoption',
          component: true,
        },
      ]}
    />
  );
}
