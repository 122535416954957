import React from 'react';
import FormAlert from '../../../Common/FormAlert/FormAlert';
import useAuth from '../../../../../hooks/useAuth';
import styles from './TeamAndMeetingSelector.module.scss';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getAbsoluteTimestamp } from '../../../../../util/formatDate';
import APIClient from '../../../../../util/APIClient';

export default function TeamAndMeetingSelector(props) {
  const { setTeam, setMeeting } = props;
  const { currentUser } = useAuth();
  const teams = currentUser.teams;
  const [teamIdInput, setTeamIdInput] = React.useState(
    teams.length === 1 ? teams[0].id : '',
  );
  const [meetingIdInput, setMeetingIdInput] = React.useState('');
  const [meetings, setMeetings] = React.useState([]);
  const [meetingsIsLoading, setMeetingsIsLoading] = React.useState(false);

  React.useEffect(() => {
    setTeam(teams.filter((t) => t.id === teamIdInput)?.[0] || null);
    setMeetings([]);
    setMeetingIdInput('');
    setMeetingsIsLoading(true);
    APIClient.get(`/user/teams/${teamIdInput}/meetings`, {
      params: {
        includeFutureOnly: true,
      },
    })
      .then((result) => {
        setMeetings(result.data);
        if (result.data.length === 1) {
          setMeetingIdInput(result.data[0].id);
          setMeeting(result.data[0]);
        }
      })
      .finally(() => {
        setMeetingsIsLoading(false);
      });
  }, [teamIdInput]);

  React.useEffect(() => {
    setMeeting(meetings.filter((m) => m.id === meetingIdInput)?.[0] || null);
  }, [meetingIdInput]);

  function handleTeamIdChange(e) {
    const teamId = e.target.value;
    setTeamIdInput(teamId);
  }

  function handleMeetingIdChange(e) {
    const meetingId = e.target.value;
    console.log(`setting meeting to: ${meetingId}`);
    setMeetingIdInput(meetingId);
  }

  const meetingSelector = React.useMemo(
    () => (
      <FormControl fullWidth>
        <InputLabel id="meetingId-label">Meeting</InputLabel>
        <Select
          labelId="meetingId-label"
          id="meetingId"
          label="Meeting"
          onChange={handleMeetingIdChange}
          value={meetingIdInput}
        >
          {meetings.map((meeting) => (
            <MenuItem value={meeting.id} key={`${meeting.id}-menuItem`}>
              {getAbsoluteTimestamp(new Date(meeting.scheduledTime))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [meetings, meetingIdInput],
  );

  return (
    <div className={styles.TeamAndMeetingSelector}>
      <div className={styles.row}>
        <FormControl fullWidth>
          <InputLabel id="teamId-label">Team</InputLabel>
          <Select
            labelId="teamId-label"
            id="teamId"
            label="Team"
            onChange={handleTeamIdChange}
            value={teamIdInput}
          >
            {teams.map((team) => (
              <MenuItem value={team.id} key={`${team.id}-menuItem`}>
                {team.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {meetingSelector}
      </div>

      {!!teamIdInput &&
        teamIdInput !== '' &&
        !meetingsIsLoading &&
        meetings.length === 0 && (
          <FormAlert
            alert={{
              severity: 'warning',
              title: 'No scheduled meetings...',
              message:
                'There are no meetings scheduled for this team! Please check again later or contact support if you believe this is a mistake.',
            }}
          />
        )}
    </div>
  );
}
