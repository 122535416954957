import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import APIClient from '../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import ScoreDisplay from '../../../Common/ScoreDisplay/ScoreDisplay';

export default function Users() {
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const { data, error, isLoading, reload } = useApiData({
    path: '/administrator/users',
    params: {
      group: 'users',
    },
  });

  const disableUsers = React.useCallback(async () => {
    await APIClient.patch('/administrator/users', {
      cognitoIds: selectedUsers,
      operation: 'disable',
    });
    reload();
  }, [selectedUsers]);

  const enableUsers = React.useCallback(async () => {
    await APIClient.patch('/administrator/users', {
      cognitoIds: selectedUsers,
      operation: 'enable',
    });
    reload();
  }, [selectedUsers]);

  if (isLoading) {
    return <Loading message="Fetching users..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const formattedData = data.map((d) => ({
    ...d,
    performanceScore: (
      <ScoreDisplay
        subjectType="user"
        scoreType="performance"
        userId={d.cognitoId}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
    processAdoptionScore: (
      <ScoreDisplay
        subjectType="user"
        scoreType="processAdoption"
        userId={d.cognitoId}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
  }));

  return (
    <Table
      rows={formattedData}
      title="Users"
      keyColumn="cognitoId"
      linkFunctionColumn="cognitoId"
      topButtons={[
        {
          label: 'Invite User',
          variant: 'contained',
          startIcon: <PersonAddIcon />,
          to: '/administrator/users/invite',
        },
      ]}
      linkFunction={(id) => `/administrator/users/${id}`}
      allowSort
      allowSelect
      onSelected={(selected) => setSelectedUsers(selected)}
      selectedActions={[
        {
          handler: enableUsers,
          label: 'Enable users',
          icon: <LockOpenIcon />,
        },
        {
          handler: disableUsers,
          label: 'Disable users',
          icon: <LockIcon />,
        },
      ]}
      columns={[
        {
          id: 'firstName',
          label: 'First Name',
          numeric: false,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'Email Address',
          numeric: false,
        },
        {
          id: 'isEnabled',
          label: 'Enabled',
          boolean: true,
        },
        {
          id: 'performanceScore',
          label: 'Performance',
          component: true,
        },
        {
          id: 'processAdoptionScore',
          label: 'Process Adoption',
          component: true,
        },
      ]}
    />
  );
}
