import React from 'react';
import styles from './CommitmentForm.module.scss';
import TextField from '@mui/material/TextField';
import usePostInitDebouncing from '../../../../../../../../hooks/usePostInitDebouncing';
import useAuth from '../../../../../../../../hooks/useAuth';
import {
  useIsLocked,
  useIsStale,
  useIsTeamLeader,
  useMeetingControls,
} from '../../../../../../../../hooks/useMeeting';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { isNumeric } from '../../../../../../../../hooks/useTreeEditor';
import FormHelperText from '@mui/material/FormHelperText';
import Menu from '../../../../../../Common/Menu/Menu';
import { useMeetingTeamGoals } from '../../Meeting';
import Tooltip from '@mui/material/Tooltip';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

const TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT = 300;
const CHECKBOX_INPUT_DEBOUNCE_TIMEOUT = 1;
const SELECT_INPUT_DEBOUNCE_TIMEOUT = 1;

export default React.memo(function CommitmentForm(props) {
  const { currentUser } = useAuth();
  const {
    commitmentId,
    cognitoId,
    label,
    note,
    startValue,
    endValue,
    isTimely,
    isSpecific,
    isAligned,
    isImpactful,
    goalId,
  } = props;
  const teamGoals = useMeetingTeamGoals();
  const isTeamLeader = useIsTeamLeader();
  const { updateCommitment, deleteCommitment } = useMeetingControls();
  const isLocked = useIsLocked();
  const isStale = useIsStale();
  const isDisabled =
    isStale ||
    (!isTeamLeader && (currentUser.cognitoId !== cognitoId || isLocked));

  // <value>Current represents the latest value either set locally or received from the server
  const [labelCurrent, setLabelCurrent] = React.useState(label);
  const [noteCurrent, setNoteCurrent] = React.useState(note);
  const [startValueCurrent, setStartValueCurrent] = React.useState(
    `${startValue}`,
  );
  const [endValueCurrent, setEndValueCurrent] = React.useState(`${endValue}`);
  const [isTimelyCurrent, setIsTimelyCurrent] = React.useState(isTimely);
  const [isSpecificCurrent, setIsSpecificCurrent] = React.useState(isSpecific);
  const [isAlignedCurrent, setIsAlignedCurrent] = React.useState(isAligned);
  const [isImpactfulCurrent, setIsImpactfulCurrent] =
    React.useState(isImpactful);
  const [goalIdCurrent, setGoalIdCurrent] = React.useState(
    goalId === null ? '' : goalId,
  );

  // <valueInput represents the value bound to the input field
  const [labelInput, setLabelInput] = React.useState(label);
  const [noteInput, setNoteInput] = React.useState(note);
  const [startValueInput, setStartValueInput] = React.useState(`${startValue}`);
  const [endValueInput, setEndValueInput] = React.useState(`${endValue}`);
  const [isTimelyInput, setIsTimelyInput] = React.useState(isTimely);
  const [isSpecificInput, setIsSpecificInput] = React.useState(isSpecific);
  const [isAlignedInput, setIsAlignedInput] = React.useState(isAligned);
  const [isImpactfulInput, setIsImpactfulInput] = React.useState(isImpactful);
  const [goalIdInput, setGoalIdInput] = React.useState(
    goalId === null ? '' : goalId,
  );

  const [labelInputError, setLabelInputError] = React.useState(null);
  const [startValueInputError, setStartValueInputError] = React.useState(null);
  const [endValueInputError, setEndValueInputError] = React.useState(null);

  // Update input value and current value when the props change. This is only triggered when the
  // context for commitments changes AND the prop value changes (due to React.memo() on the component).
  React.useEffect(() => {
    if (label !== labelInput) {
      setLabelInput(label);
      setLabelCurrent(label);
    }
  }, [label]);

  React.useEffect(() => {
    if (note !== noteInput) {
      setNoteInput(note);
      setNoteCurrent(note);
    }
  }, [note]);

  React.useEffect(() => {
    const newStartValue = `${startValue}`;
    if (newStartValue !== startValueInput) {
      setStartValueInput(newStartValue);
      setStartValueCurrent(newStartValue);
    }
  }, [startValue]);

  React.useEffect(() => {
    const newEndValue = `${endValue}`;
    if (newEndValue !== endValueInput) {
      setEndValueInput(newEndValue);
      setEndValueCurrent(newEndValue);
    }
  }, [endValue]);

  React.useEffect(() => {
    if (isTimely !== isTimelyInput) {
      setIsTimelyInput(isTimely);
      setIsTimelyCurrent(isTimely);
    }
  }, [isTimely]);

  React.useEffect(() => {
    if (isSpecific !== isSpecificInput) {
      setIsSpecificInput(isSpecific);
      setIsSpecificCurrent(isSpecific);
    }
  }, [isSpecific]);

  React.useEffect(() => {
    if (isAligned !== isAlignedInput) {
      setIsAlignedInput(isAligned);
      setIsAlignedCurrent(isAligned);
    }
  }, [isAligned]);

  React.useEffect(() => {
    if (isImpactful !== isImpactfulInput) {
      setIsImpactfulInput(isImpactful);
      setIsImpactfulCurrent(isImpactful);
    }
  }, [isImpactful]);

  React.useEffect(() => {
    const newGoalId = goalId === null ? '' : goalId;
    if (newGoalId !== goalIdInput) {
      setGoalIdInput(newGoalId);
      setGoalIdCurrent(newGoalId);
    }
  }, [goalId]);

  usePostInitDebouncing(
    () => {
      if (!labelInputError) {
        if (labelInput !== labelCurrent) {
          setLabelCurrent(labelInput);
          updateCommitment(cognitoId, commitmentId, { label: labelInput });
        }
      }
    },
    [labelInput, labelInputError],
    TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (noteInput !== noteCurrent) {
        setNoteCurrent(noteInput);
        updateCommitment(cognitoId, commitmentId, { note: noteInput });
      }
    },
    [noteInput],
    TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (!startValueInputError) {
        if (startValueInput !== startValueCurrent) {
          setStartValueCurrent(startValueInput);
          updateCommitment(cognitoId, commitmentId, {
            startValue: Number(startValueInput),
          });
        }
      }
    },
    [startValueInput, startValueInputError],
    TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (!endValueInputError) {
        if (endValueInput !== endValueCurrent) {
          setEndValueCurrent(endValueInput);
          updateCommitment(cognitoId, commitmentId, {
            endValue: Number(endValueInput),
          });
        }
      }
    },
    [endValueInput, endValueInputError],
    TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (isTimelyInput !== isTimelyCurrent) {
        setIsTimelyCurrent(isTimelyInput);
        updateCommitment(cognitoId, commitmentId, { isTimely: isTimelyInput });
      }
    },
    [isTimelyInput],
    CHECKBOX_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (isSpecificInput !== isSpecificCurrent) {
        setIsSpecificCurrent(isSpecificInput);
        updateCommitment(cognitoId, commitmentId, {
          isSpecific: isSpecificInput,
        });
      }
    },
    [isSpecificInput],
    CHECKBOX_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (isAlignedInput !== isAlignedCurrent) {
        setIsAlignedCurrent(isAlignedInput);
        updateCommitment(cognitoId, commitmentId, {
          isAligned: isAlignedInput,
        });
      }
    },
    [isAlignedInput],
    CHECKBOX_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (isImpactfulInput !== isImpactfulCurrent) {
        setIsImpactfulCurrent(isImpactfulInput);
        updateCommitment(cognitoId, commitmentId, {
          isImpactful: isImpactfulInput,
        });
      }
    },
    [isImpactfulInput],
    CHECKBOX_INPUT_DEBOUNCE_TIMEOUT,
  );

  usePostInitDebouncing(
    () => {
      if (goalIdInput !== goalIdCurrent) {
        setGoalIdCurrent(goalIdInput);
        updateCommitment(cognitoId, commitmentId, {
          goalId: goalIdInput === '' ? null : goalIdInput,
        });
      }
    },
    [goalIdInput],
    SELECT_INPUT_DEBOUNCE_TIMEOUT,
  );

  const handleLabelInputChange = React.useCallback((e) => {
    setLabelInput(e.target.value);
  }, []);

  const handleNoteInputChange = React.useCallback((e) => {
    setNoteInput(e.target.value);
  }, []);

  const handleStartValueInputChange = React.useCallback((e) => {
    setStartValueInput(e.target.value);
  }, []);

  const handleEndValueInputChange = React.useCallback((e) => {
    setEndValueInput(e.target.value);
  }, []);

  const handleIsTimelyInputChange = React.useCallback((e) => {
    setIsTimelyInput(e.target.checked);
  }, []);

  const handleIsSpecificInputChange = React.useCallback((e) => {
    setIsSpecificInput(e.target.checked);
  }, []);

  const handleIsAlignedInputChange = React.useCallback((e) => {
    setIsAlignedInput(e.target.checked);
  }, []);

  const handleIsImpactfulInputChange = React.useCallback((e) => {
    setIsImpactfulInput(e.target.checked);
  }, []);

  const handleGoalIdInputChange = React.useCallback((e) => {
    setGoalIdInput(e.target.value);
  }, []);

  React.useEffect(() => {
    if (!isNumeric(startValueInput)) {
      setStartValueInputError('Start value must be a number!');
    } else {
      setStartValueInputError(null);
    }
    if (!isNumeric(endValueInput)) {
      setEndValueInputError('End value must be a number!');
    } else {
      setEndValueInputError(null);
    }
    if (startValueInput === endValueInput) {
      setStartValueInputError('Start value must be different from end value!');
      setEndValueInputError('End value must be different from start value!');
    }
  }, [startValueInput, endValueInput]);

  React.useEffect(() => {
    if (labelInput.length < 10) {
      setLabelInputError('Label needs to be at least 10 characters long!');
    } else if (labelInput.length > 250) {
      setLabelInputError(
        'Label cannot be longer than 250 characters! Use note field to provide additional information instead.',
      );
    } else {
      setLabelInputError(null);
    }
  }, [labelInput]);

  const labelTextField = React.useMemo(
    () => (
      <TextField
        label="Label"
        value={labelInput}
        onChange={handleLabelInputChange}
        disabled={isDisabled}
        error={!isDisabled && !!labelInputError}
        fullWidth
      />
    ),
    [isDisabled, labelInput, labelInputError],
  );

  const noteTextField = React.useMemo(
    () => (
      <TextField
        label="Note (optional)"
        value={noteInput}
        onChange={handleNoteInputChange}
        disabled={isDisabled}
        fullWidth
      />
    ),
    [noteInput, isDisabled],
  );
  const startValueTextField = React.useMemo(
    () => (
      <TextField
        label="Start Value"
        value={startValueInput}
        onChange={handleStartValueInputChange}
        disabled={isDisabled}
        error={!isDisabled && !!startValueInputError}
      />
    ),
    [startValueInput, startValueInputError, isDisabled],
  );
  const endValueTextField = React.useMemo(
    () => (
      <TextField
        label="End Value"
        value={endValueInput}
        onChange={handleEndValueInputChange}
        disabled={isDisabled}
        error={!isDisabled && !!endValueInputError}
      />
    ),
    [endValueInput, endValueInputError, isDisabled],
  );

  const isTimelyCheckbox = React.useMemo(
    () => (
      <FormControlLabel
        label="Timely"
        control={<Checkbox size={'small'} />}
        checked={isTimelyInput}
        onChange={handleIsTimelyInputChange}
        disabled={isStale || !isTeamLeader}
        labelPlacement="bottom"
      />
    ),
    [isTimelyInput, isTeamLeader, isStale],
  );
  const isSpecificCheckbox = React.useMemo(
    () => (
      <FormControlLabel
        label="Specific"
        control={<Checkbox size={'small'} />}
        checked={isSpecificInput}
        onChange={handleIsSpecificInputChange}
        disabled={isStale || !isTeamLeader}
        labelPlacement="bottom"
      />
    ),
    [isSpecificInput, isTeamLeader, isStale],
  );
  const isAlignedCheckbox = React.useMemo(
    () => (
      <FormControlLabel
        label="Aligned"
        control={<Checkbox size={'small'} />}
        checked={isAlignedInput}
        onChange={handleIsAlignedInputChange}
        disabled={isStale || !isTeamLeader}
        labelPlacement="bottom"
      />
    ),
    [isAlignedInput, isTeamLeader, isStale],
  );
  const isImpactfulCheckbox = React.useMemo(
    () => (
      <FormControlLabel
        label="Impactful"
        control={<Checkbox size={'small'} />}
        checked={isImpactfulInput}
        onChange={handleIsImpactfulInputChange}
        disabled={isStale || !isTeamLeader}
        labelPlacement="bottom"
      />
    ),
    [isImpactfulInput, isTeamLeader, isStale],
  );

  const goalIdSelect = React.useMemo(
    () => (
      <FormControl disabled={isDisabled} fullWidth>
        <InputLabel id={`${commitmentId}-goalId-label`}>Goal</InputLabel>
        <Select
          labelId={`${commitmentId}-goalId-label`}
          id={`${commitmentId}-goalId`}
          label="Goal"
          onChange={handleGoalIdInputChange}
          value={goalIdInput}
          renderValue={(selected) =>
            teamGoals.filter((g) => g.id === selected)?.[0]?.label
          }
        >
          <MenuItem value={''}>(None)</MenuItem>
          {teamGoals.map((g) => (
            <Tooltip
              key={`${commitmentId}-${g.id}-tooltip`}
              title={
                <p>
                  <strong>{g.label}</strong> - <em>{g.description}</em>
                </p>
              }
              placement="right"
              value={g.id}
            >
              <MenuItem key={`${commitmentId}-${g.id}-menuItem`}>
                {g.label}
              </MenuItem>
            </Tooltip>
          ))}
        </Select>
      </FormControl>
    ),
    [isDisabled, commitmentId, teamGoals, goalIdInput],
  );

  const menu = React.useMemo(
    () => (
      <Menu
        disabled={isDisabled}
        items={[
          {
            label: `Remove this commitment`,
            handler: () => {
              deleteCommitment(cognitoId, commitmentId);
            },
            disabled: isDisabled,
          },
        ]}
      />
    ),
    [deleteCommitment, cognitoId, commitmentId, isDisabled],
  );
  return (
    <div className={styles.commitmentForm}>
      <div className={styles.contentRow}>
        <div className={styles.stack}>
          {labelTextField}
          <div className={styles.row}>
            {noteTextField}
            {startValueTextField}
            <span className={styles.icon}>
              <EastOutlinedIcon fontSize="inherit" />
            </span>
            {endValueTextField}
          </div>
        </div>
        <div className={styles.rightStack}>
          <div className={styles.checkboxRow}>
            {isTimelyCheckbox}
            {isSpecificCheckbox}
            {isAlignedCheckbox}
            {isImpactfulCheckbox}
            <div className={styles.menu}>{menu}</div>
          </div>
          {goalIdSelect}
        </div>
      </div>
      {!isDisabled && (
        <>
          {[labelInputError, startValueInputError, endValueInputError]
            .filter((error) => !!error)
            .map((e, i, a) =>
              i !== a.length - 1
                ? e
                : [e, 'Your data will not be saved until you fix the errors!'],
            ) // Append a save warning if there is at least one error in the array
            .flat()
            .map((error) => (
              <FormHelperText error key={error}>
                {`${error}`}
              </FormHelperText>
            ))}
        </>
      )}
    </div>
  );
});
