import React from 'react';
import styles from './ScoreDisplay.module.scss';
import ScoreSvg from './ScoreSvg';
import useApiData from '../../../../hooks/useApiData';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { getAbsoluteTimestamp } from '../../../../util/formatDate';

function getPath({ scoreType, subjectType, userId, teamId }) {
  if (!['performance', 'processAdoption'].includes(scoreType)) {
    throw new Error('Invalid score type!');
  }

  switch (subjectType) {
    case 'user':
      if (!userId) {
        throw new Error('User ID needs to be provided!');
      }
      return `/score/users/${userId}/${scoreType}`;
    case 'teamMember':
      if (!teamId || !userId) {
        throw new Error('Both Team ID and User ID need to be provided!');
      }
      return `/score/teams/${teamId}/teamMembers/${userId}/${scoreType}`;
    case 'team':
      if (!teamId) {
        throw new Error('Team ID needs to be provided!');
      }
      return `/score/teams/${teamId}/${scoreType}`;
    case 'organisation':
      return `/score/${scoreType}`;
    default:
      throw new Error(
        'Invalid subject type! Subject type must be one of ["user", "team", "teamMember", "organisation"].',
      );
  }
}

function getParams({ fromDate, toDate }) {
  const params = {};
  if (fromDate) {
    params.fromDate = new Date(fromDate).toISOString().substring(0, 10);
  }
  if (toDate) {
    params.toDate = new Date(toDate).toISOString().substring(0, 10);
  }
  return params;
}

function getTooltipTitle({ scoreType, fromDate, toDate, score }) {
  let header = '';
  let body = 'Measured ';
  switch (scoreType) {
    case 'performance':
      header += 'Performance';
      break;
    case 'processAdoption':
      header += 'Process adoption';
      break;
  }
  header += ` Score - `;
  if (fromDate && toDate) {
    body += `between ${getAbsoluteTimestamp(new Date(fromDate))} and ${getAbsoluteTimestamp(new Date(toDate))}`;
  } else if (!fromDate && toDate) {
    body += `since creation until ${getAbsoluteTimestamp(new Date(toDate))}`;
  } else if (fromDate && !toDate) {
    body += `since ${getAbsoluteTimestamp(new Date(fromDate))} until now`;
  } else {
    body += 'since creation until now';
  }
  body += '.';
  return (
    <p style={{ fontSize: '1rem' }}>
      {header}
      <span style={{ fontWeight: 600 }}>{(score * 100).toFixed()} </span> out of
      100
      <br />
      <span
        style={{ fontStyle: 'italic', marginTop: '.5rem', display: 'block' }}
      >
        {body}
      </span>
    </p>
  );
}

export default function ScoreDisplay(props) {
  const { grow } = props;
  const path = getPath(props);
  const params = getParams(props);
  const { data, error, isLoading } = useApiData({
    path,
    params,
  });
  const tooltipTitle = isLoading
    ? 'Loading score...'
    : error
      ? 'Could not load score!'
      : getTooltipTitle({ ...props, score: data.score });
  return (
    <Tooltip title={tooltipTitle}>
      <div className={`${styles.scoreDisplay} ${grow ? styles.grow : ''}`}>
        {(isLoading && <CircularProgress />) || (error && <p>ERR</p>) || (
          <ScoreSvg score={data.score} />
        )}
      </div>
    </Tooltip>
  );
}
