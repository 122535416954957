import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, useParams } from 'react-router-dom';
import styles from './Goals.module.scss';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import GoalEntry from './GoalEntry/GoalEntry';
import useAuth from '../../../../../../hooks/useAuth';

export default function Goals() {
  const { teamId } = useParams();
  const { currentUser } = useAuth();
  const team = currentUser.teams.filter((t) => t.id === teamId)?.[0] || {
    label: 'Unknown Team',
  };
  const isLead = team.isLead;
  const { data, error, isLoading, reload } = useApiData({
    path: `/user/teams/${teamId}/goals`,
    params: {
      includeGoalMeasures: true,
      includeTeams: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching goals..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  // TODO: Replace the team list with team counts

  return (
    <div className={styles.goals}>
      <div className={styles.header}>
        <h1>Goals for {team.label}</h1>
        <div className={styles.row}>
          <Button
            variant="outlined"
            component={Link}
            to="hierarchy"
            startIcon={<AccountTreeOutlinedIcon />}
          >
            View as hierarchy
          </Button>
          {isLead && (
            <Button
              variant="outlined"
              component={Link}
              to="hierarchy/edit"
              startIcon={<EditOutlinedIcon />}
            >
              Edit goals
            </Button>
          )}
        </div>
      </div>
      {data.map((goal) => (
        <GoalEntry key={goal.id} id={goal.id} goal={goal} reload={reload} />
      ))}
    </div>
  );
}
