import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './PrepareCommitmentDialog.module.scss';
import PrepareCommitmentForm from '../PrepareCommitmentForm/PrepareCommitmentForm';
import Form from '../../../Common/Form/Form';
import APIClient from '../../../../../util/APIClient';
import { getAbsoluteTimestamp } from '../../../../../util/formatDate';
import FormAlert from '../../../Common/FormAlert/FormAlert';

export default function PrepareCommitmentDialog({
  isOpen,
  close,
  reload = () => {},
  team,
  meeting,
  commitment,
}) {
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFormAlert(null);
    setIsSubmitting(false);
    close();
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);

    const parsedData = {
      ...data,
      startValue: parseFloat(data.startValue),
      endValue: parseFloat(data.endValue),
    };
    if (!data.goalId || data.goalId === '') {
      delete parsedData.goalId;
    }
    if (commitment) {
      parsedData.id = commitment.id;
    }

    try {
      const response = await APIClient.put(
        `/user/teams/${team.id}/meetings/${meeting.id}/commitments/`,
        parsedData,
      );
      reload();
      close();
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not save commitment!',
        message:
          'Something went wrong when saving commitment. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAbort = () => {
    handleClose();
  };

  const handleFocusLost = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleFocusLost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Prepare commitment
      </DialogTitle>
      <div className={styles.content}>
        <DialogContentText id="alert-dialog-description">
          Use the form below to prepare the commitment for your upcoming meeting
          of team
          <span style={{ fontStyle: 'italic', paddingLeft: '.2rem' }}>
            {team.label}
          </span>{' '}
          on{' '}
          <span style={{ fontStyle: 'italic' }}>
            {getAbsoluteTimestamp(new Date(meeting.scheduledTime))}
          </span>
          .
        </DialogContentText>

        <Form onSubmit={(data) => handleSubmit(data)}>
          <PrepareCommitmentForm
            goals={team.goals}
            commitment={commitment}
            disabled={isSubmitting}
          />
          {formAlert && <FormAlert alert={formAlert} />}
          <div className={styles.buttons}>
            <Button onClick={handleAbort} color="primary" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
        </Form>
      </div>
    </Dialog>
  );
}
