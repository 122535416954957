import React from 'react';
import styles from './Changelog.module.scss';
import Version from '../../Common/UpdateDialog/Version/Version';

export default function Changelog() {
  const [versions, setVersions] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const availableVersions = await fetch(`/changelog/versions.json`).then(
        (r) => r.json(),
      );
      // Sort the versions in the order of newest to latest
      availableVersions.sort().reverse();
      setVersions(availableVersions);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const versionNotes = await Promise.all(
        versions.map((v) =>
          fetch(`/changelog/${v}/notes.json`).then((r) => r.json()),
        ),
      );
      const mappedNotes = versionNotes.map((version) => ({
        ...version,
        content: version.content.map((entry) => {
          const newEntry = { ...entry };
          if (newEntry?.attributes?.src) {
            newEntry.attributes.src = `/changelog/${version.version}/${entry.attributes.src}`;
          }
          return newEntry;
        }),
      }));

      setData(mappedNotes);
    })();
  }, [versions]);

  return (
    <div className={styles.changelog}>
      <div className={styles.topHeader}>
        <h1>Changelog</h1>
      </div>
      <div>
        {data.map((notes) => (
          <Version
            key={notes.version}
            version={notes.version}
            updateType={notes.updateType}
            date={notes.date}
            content={notes.content}
          />
        ))}
      </div>
    </div>
  );
}
