import React from 'react';
import styles from './UpdateDialog.module.scss';
import Version from './Version/Version';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function UpdateDialog({ userLastVersion }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [displayVersions, setDisplayVersions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const availableVersions = await fetch(`/changelog/versions.json`).then(
        (r) => r.json(),
      );
      // Sort the versions in the order of newest to latest
      availableVersions.sort().reverse();
      if (
        !userLastVersion ||
        userLastVersion === process.env.REACT_APP_VERSION ||
        !/(\d+)\.(\d+)\.(\d+)/i.test(userLastVersion)
      ) {
        return;
      }
      const [, uMajor, uMinor, uPatch] =
        userLastVersion.match(/(\d+)\.(\d+)\.(\d+)/i);
      const newDisplayVersions = availableVersions.filter(
        (availableVersion) => {
          const [, major, minor, patch] =
            availableVersion.match(/(\d+)\.(\d+)\.(\d+)/i);
          return (
            major > uMajor ||
            (major >= uMajor && minor > uMinor) ||
            (major >= uMajor && minor >= uMinor && patch > uPatch)
          );
        },
      );
      if (newDisplayVersions.length > 0) {
        setDisplayVersions(newDisplayVersions);
      }
    })();
  }, [userLastVersion]);

  React.useEffect(() => {
    (async () => {
      const versionNotes = await Promise.all(
        displayVersions.map((v) =>
          fetch(`/changelog/${v}/notes.json`).then((r) => r.json()),
        ),
      );
      const mappedNotes = versionNotes.map((version) => ({
        ...version,
        content: version.content.map((entry) => {
          const newEntry = { ...entry };
          if (newEntry?.attributes?.src) {
            newEntry.attributes.src = `/changelog/${version.version}/${entry.attributes.src}`;
          }
          return newEntry;
        }),
      }));

      setData(mappedNotes);
      setIsOpen(mappedNotes.length > 0);
    })();
  }, [displayVersions]);

  return (
    <Dialog open={isOpen} maxWidth={'md'}>
      <div className={styles.updateDialog}>
        <div className={styles.header}>
          <h1>What&apos;s New</h1>
          <Tooltip title="Close">
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={styles.content}>
          {data.map((notes) => (
            <Version
              key={notes.version}
              version={notes.version}
              updateType={notes.updateType}
              date={notes.date}
              content={notes.content}
            />
          ))}
        </div>
      </div>
    </Dialog>
  );
}
