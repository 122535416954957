import React from 'react';
import styles from './PrepareCommitments.module.scss';
import FormAlert from '../../Common/FormAlert/FormAlert';
import APIClient from '../../../../util/APIClient';
import TeamAndMeetingSelector from './TeamAndMeetingSelector/TeamAndMeetingSelector';
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PrepareCommitmentDialog from './PrepareCommitmentDialog/PrepareCommitmentDialog';
import Loading from '../../Common/Loading/Loading';
import PreparedCommitment from './PreparedCommitment/PreparedCommitment';

export default function PrepareCommitments() {
  const [formAlert, setFormAlert] = React.useState(null);

  const [team, setTeam] = React.useState(null);
  const [meeting, setMeeting] = React.useState(null);
  const [commitmentToEdit, setCommitmentToEdit] = React.useState(null);
  const [commitments, setCommitments] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const fetchCommitments = React.useCallback(() => {
    if (!team || !meeting) {
      return;
    }
    setIsLoading(true);
    APIClient.get(`/user/teams/${team.id}/meetings/${meeting.id}/commitments`, {
      params: {
        includeGoals: true,
        includeOnlyPrepared: true,
      },
    })
      .then((result) => {
        setCommitments(result.data);
      })
      .catch((error) => {
        setFormAlert({
          severity: 'error',
          title: 'Could not load commitments!',
          message:
            'Something went wrong when loading the commitments for this meeting. Please contact the administrator for further support.',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [team, meeting]);

  function editCommitment(commitment) {
    setCommitmentToEdit(commitment);
    setIsDialogOpen(true);
  }

  function deleteCommitment(commitment) {
    APIClient.delete(
      `/user/teams/${team.id}/meetings/${meeting.id}/commitments/${commitment.id}`,
    )
      .then((result) => {
        setCommitments((current) =>
          current.filter((c) => c.id !== commitment.id),
        );
      })
      .catch((error) => {
        setFormAlert({
          severity: 'error',
          title: 'Could not delete commitment!',
          message:
            'Something went wrong when deleting the commitments. Please contact the administrator for further support.',
        });
      });
  }

  React.useEffect(() => {
    fetchCommitments();
  }, [meeting]);

  return (
    <div className={styles.prepareCommitments}>
      <h1>Prepare commitments</h1>
      <p className={styles.description}>
        View and create new commitments for the upcoming meetings using the form
        below. These commitments will be visible to all participants when the
        meeting starts and allow you, or the team leaders to make further
        adjustments. Should you miss your meeting and it still takes place,
        these commitments will be assigned to you unless modified by the team
        leaders.
      </p>
      <TeamAndMeetingSelector setTeam={setTeam} setMeeting={setMeeting} />
      {!!team && !!meeting && (
        <>
          {(isLoading && (
            <div className={styles.loadingWrapper}>
              <Loading message="Fetching prepared commitments..." />
            </div>
          )) ||
            (commitments !== null && commitments.length > 0 && (
              <>
                <h2>Prepared commitments</h2>
                <div className={styles.commitments}>
                  {commitments.map((c) => (
                    <PreparedCommitment
                      key={`commitment-${c.id}`}
                      commitment={c}
                      editCommitment={() => editCommitment(c)}
                      deleteCommitment={() => deleteCommitment(c)}
                    />
                  ))}
                </div>
              </>
            ))}

          <div className={styles.buttons}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="outlined"
              onClick={() => setIsDialogOpen(true)}
            >
              Prepare new commitment
            </Button>
          </div>

          <PrepareCommitmentDialog
            isOpen={isDialogOpen}
            close={() => {
              setIsDialogOpen(false);
              setCommitmentToEdit(null);
            }}
            team={team}
            meeting={meeting}
            commitment={commitmentToEdit}
            reload={fetchCommitments}
          />
        </>
      )}
      {formAlert && <FormAlert alert={formAlert} />}
    </div>
  );
}
