import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './Layout.module.scss';
import Navbar from './Navbar/Navbar';
import Loading from '../../_view/Common/Loading/Loading';
import TopBar from './TopBar/TopBar';
import useAuth from '../../../hooks/useAuth';
import UpdateDialog from '../../_view/Common/UpdateDialog/UpdateDialog';

export default function Layout() {
  const { isAuthenticationComplete, isSignedIn, userLastVersion } = useAuth();
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      <TopBar />
      <div className={styles.columnWrapper}>
        {isSignedIn && (
          <div className={styles.leftColumn}>
            <div className={styles.navbarWrapper}>
              <div className={styles.navbarWrapperInner}>
                <Navbar />
                {userLastVersion !== null && (
                  <UpdateDialog userLastVersion={userLastVersion} />
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styles.rightColumn}>
          <div className={styles.view}>
            {isAuthenticationComplete ? (
              <Outlet key={`${location.pathname}`} />
            ) : (
              <Loading message="Authenticating..." />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
