import React from 'react';
import FormSelect from '../../../Common/FormSelect/FormSelect';
import styles from './PrepareCommitmentForm.module.scss';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import FormTextField from '../../../Common/FormTextField/FormTextField';
import { useFormContext } from 'react-hook-form';

export default function PrepareCommitmentForm(props) {
  const { goals, commitment, disabled } = props;
  const { setValue, trigger, getValues, clearErrors } = useFormContext();

  const goalOptions = [
    { key: 'none-option', value: '', label: '(None)' },
    ...goals.map((g) => ({
      key: g.id,
      value: g.id,
      label: g.label,
    })),
  ];

  React.useEffect(() => {
    if (commitment) {
      setValue('label', commitment.label);
      setValue('note', commitment.note);
      setValue('startValue', commitment.startValue);
      setValue('endValue', commitment.endValue);
      if (commitment.goalId) {
        if (goals.map((g) => g.id === commitment.goalId)) {
          setValue('goalId', commitment.goalId);
        }
      }
      trigger();
    }
  }, [commitment]);

  return (
    <div className={styles.prepareCommitmentForm}>
      <div className={styles.stack}>
        <FormTextField
          name="label"
          label="Label"
          rules={{
            required: { value: true, message: 'Label is required.' },
          }}
          preserveHeight
          disabled={disabled}
        />
        <FormTextField
          name="note"
          label="Note (optional)"
          preserveHeight
          disabled={disabled}
        />

        <div className={styles.row}>
          <FormTextField
            name="startValue"
            label="Start Value"
            type="number"
            rules={{
              required: {
                value: true,
                message: 'Start value is required.',
              },
              validate: (value) => {
                const [endValue] = getValues(['endValue']);
                if (value === endValue) {
                  return 'Must differ from end value.';
                }
                clearErrors('endValue');
              },
            }}
            preserveHeight
            disabled={disabled}
          />
          <span className={styles.icon}>
            <EastOutlinedIcon fontSize="inherit" />
          </span>
          <FormTextField
            name="endValue"
            label="End Value"
            type="number"
            rules={{
              required: {
                value: true,
                message: 'End value is required.',
              },
              validate: (value) => {
                const [startValue] = getValues(['startValue']);
                if (value === startValue) {
                  // return 'Cannot be the same as start.';
                  return 'Must differ from start value.';
                }
                clearErrors('startValue');
              },
            }}
            preserveHeight
            disabled={disabled}
          />
        </div>
        <FormSelect
          name="goalId"
          label="Goal"
          options={goalOptions}
          variant="outlined"
          preserveHeight
          disabled={disabled}
        />
      </div>
    </div>
  );
}
