import React from 'react';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import styles from './GoalHierarchyForm.module.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useAuth from '../../../../../../../hooks/useAuth';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import useTreeEditor from '../../../../../../../hooks/useTreeEditor';

export default function GoalHierarchyForm() {
  const { teamId } = useParams();
  const { currentUser } = useAuth();
  const isLead = currentUser.teams.filter((t) => t.id === teamId)?.[0]?.isLead;
  const { isExpanded, setIsExpanded } = useTreeEditor();
  return (
    <div className={styles.goalHierarchyForm}>
      {isLead && (
        <Button
          sx={{ background: '#ffffff' }}
          component={Link}
          to={'edit'}
          variant="outlined"
          startIcon={<EditOutlinedIcon />}
        >
          Edit Goal Hierarchy
        </Button>
      )}
      <Button
        onClick={() => setIsExpanded((current) => !current)}
        variant="outlined"
        sx={{ background: '#ffffff' }}
        startIcon={
          isExpanded ? <UnfoldLessOutlinedIcon /> : <UnfoldMoreOutlinedIcon />
        }
      >
        {isExpanded ? 'Condense view' : 'Expand view'}
      </Button>
    </div>
  );
}
