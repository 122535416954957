import React from 'react';
import { v4 as uuid } from 'uuid';
import TreeEditorContext from '../contexts/TreeEditorContext';
import useDidMount from './useDidMount';

export default function useTreeEditor() {
  return React.useContext(TreeEditorContext);
}

// This won't copy functions and will fail on circular refs.
function deepCopy(a) {
  return JSON.parse(JSON.stringify(a));
}

export function isNumeric(a) {
  return (
    typeof a === 'number' ||
    (typeof a === 'string' && !Number.isNaN(a) && !Number.isNaN(parseFloat(a)))
  );
}

export function getNewGoalNode(isRoot = false) {
  return {
    children: [],
    attributes: {
      nodeType: 'GOAL',
      id: uuid(),
      isRoot,
      label: '',
      description: '',
      startValue: '',
      endValue: '',
      startTime: '',
      endTime: '',
    },
  };
}

function findNodeById(node, id, parent) {
  if (node.attributes.id === id) {
    return { node, parent };
  }
  if (!Array.isArray(node.children) || node.children.length === 0) {
    return null;
  }
  for (let i = 0; i < node.children.length; i += 1) {
    const result = findNodeById(node.children[i], id, node);
    if (result !== null) {
      return result;
    }
  }
  return null;
}

function formatHierarchiesIntoNodeTree(hierarchies, id, label) {
  return {
    children: hierarchies.map((hierarchy) =>
      formatHierarchyIntoNodeTree(hierarchy),
    ),
    attributes: {
      nodeType: 'GROUP',
      id,
      label,
    },
  };
}

function formatHierarchyIntoNodeTree(hierarchy) {
  const dependencies = Array.isArray(hierarchy.dependencies)
    ? hierarchy.dependencies
    : [];
  const node = {
    children: dependencies.map((dependency) =>
      formatHierarchyIntoNodeTree(dependency),
    ),
    attributes: {
      nodeType: 'GOAL',
    },
  };
  Object.keys(hierarchy)
    .filter((key) => key !== 'dependencies')
    .forEach((key) => {
      node.attributes[key] = hierarchy[key];
    });
  return node;
}

function formatNodeTreeIntoHierarchy(node) {
  const children = Array.isArray(node.children) ? node.children : [];
  const hierarchy = {
    dependencies: children.map((dependency) =>
      formatNodeTreeIntoHierarchy(dependency),
    ),
  };
  Object.keys(node.attributes).forEach((key) => {
    hierarchy[key] = node.attributes[key];
  });
  return hierarchy;
}

function getTreeDepth(node) {
  if (Array.isArray(node.children)) {
    return (
      1 +
      Math.max(0, ...node.children.map((childNode) => getTreeDepth(childNode)))
    );
  }
  return 1;
}

function formatHierarchyOrHierarchiesIntoNodeTree(data, id, label) {
  if (Array.isArray(data)) {
    return formatHierarchiesIntoNodeTree(data, id, label);
  }
  return formatHierarchyIntoNodeTree(data);
}

export function TreeEditorProvider(props) {
  const {
    initialData,
    reload = () => {},
    teams,
    children,
    id: treeId,
    label,
    isTeamEditor,
  } = props;
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [data, setData] = React.useState(
    (initialData &&
      formatHierarchyOrHierarchiesIntoNodeTree(initialData, treeId, label)) ||
      getNewGoalNode(true),
  );
  const didMount = useDidMount();

  React.useEffect(() => {
    setData(
      (initialData &&
        formatHierarchyOrHierarchiesIntoNodeTree(initialData, treeId, label)) ||
        getNewGoalNode(true),
    );
  }, [initialData, treeId, label]);

  React.useEffect(() => {
    if (!didMount) {
      setData((currentData) => deepCopy(currentData));
    }
  }, [didMount]);

  const removeNode = React.useCallback((nodeId) => {
    setData((currentData) => {
      const newData = deepCopy(currentData);

      const result = findNodeById(newData, nodeId);
      if (!result) {
        console.warn('Node with selected id does not exist!');
        return newData;
      }
      result.parent.children = result.parent.children.filter(
        (n) => n.attributes.id !== nodeId,
      );
      return newData;
    });
  }, []);

  const addNode = React.useCallback(
    (parentNodeId) => {
      setData((currentData) => {
        const newData = deepCopy(currentData);
        const result = findNodeById(newData, parentNodeId);
        if (!result) {
          console.warn('Node with selected id does not exist!');
          return newData;
        }
        const { node } = result;
        if (!Array.isArray(node.children)) {
          node.children = [];
        }
        const newNode = getNewGoalNode();
        newNode.attributes.isTeamGoal = isTeamEditor;
        node.children.push(newNode);
        return newData;
      });
    },
    [isTeamEditor],
  );

  const updateNode = React.useCallback((nodeId, newAttributes) => {
    setData((currentData) => {
      const newData = deepCopy(currentData);
      const result = findNodeById(newData, nodeId);
      if (!result) {
        console.warn('Node with selected id does not exist!');
        return newData;
      }
      const { node } = result;
      node.attributes = {
        ...node.attributes,
        ...newAttributes,
      };
      return newData;
    });
  }, []);

  const getGoalHierarchy = React.useCallback(
    () => formatNodeTreeIntoHierarchy(data),
    [data],
  );

  const getGoalHierarchies = React.useCallback(() => {
    if (data.attributes.nodeType === 'GROUP') {
      return data.children.map((n) => formatNodeTreeIntoHierarchy(n));
    }
    return [formatNodeTreeIntoHierarchy(data)];
  }, [data]);

  const getGoalById = React.useCallback((id) => findNodeById(data, id), [data]);

  const getDepth = React.useCallback(() => getTreeDepth(data), [data]);

  const [context, setContext] = React.useState({
    data,
    reload,
    removeNode,
    addNode,
    updateNode,
    hasError,
    setHasError,
    getGoalHierarchy,
    getGoalHierarchies,
    getDepth,
    teams,
    getGoalById,
    isExpanded,
    setIsExpanded,
  });

  React.useEffect(() => {
    setContext({
      data,
      reload,
      removeNode,
      addNode,
      updateNode,
      hasError,
      setHasError,
      getGoalHierarchy,
      getGoalHierarchies,
      getDepth,
      teams,
      getGoalById,
      isExpanded,
      setIsExpanded,
    });
  }, [
    data,
    reload,
    removeNode,
    addNode,
    updateNode,
    hasError,
    setHasError,
    getGoalHierarchy,
    getGoalHierarchies,
    getDepth,
    teams,
    getGoalById,
    isExpanded,
    setIsExpanded,
  ]);

  return (
    <TreeEditorContext.Provider value={context}>
      {children}
    </TreeEditorContext.Provider>
  );
}
