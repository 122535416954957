import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './Team.module.scss';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import ScoreDisplay from '../../../Common/ScoreDisplay/ScoreDisplay';

function teamMemberSortFn(a, b) {
  if (a.isLead && b.isLead) {
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  }
  if (a.isLead) {
    return -1;
  }
  if (b.isLead) {
    return 1;
  }
  if (a.fullName < b.fullName) {
    return -1;
  }
  if (a.fullName > b.fullName) {
    return 1;
  }
  return 0;
}

export default function Team() {
  const { teamId } = useParams();
  const { data, error, isLoading } = useApiData({
    path: `/user/teams/${teamId}`,
    params: {
      includeTeamMembers: true,
      includeGoals: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching team..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const userData = data.teamMembers.map((tm) => ({
    ...tm,
    fullName: `${tm.firstName} ${tm.lastName}`,
    performanceScore: (
      <ScoreDisplay
        subjectType="teamMember"
        scoreType="performance"
        teamId={teamId}
        userId={tm.cognitoId}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
    processAdoptionScore: (
      <ScoreDisplay
        subjectType="teamMember"
        scoreType="processAdoption"
        teamId={teamId}
        userId={tm.cognitoId}
        fromDate={new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
          .toISOString()
          .substring(0, 10)}
      />
    ),
  }));

  userData.sort(teamMemberSortFn);

  return (
    <div className={styles.team}>
      <h1>Team: {data.label}</h1>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.row}>
            <div className={styles.stack}>
              <div className={styles.score}>
                <ScoreDisplay
                  scoreType="performance"
                  subjectType="team"
                  teamId={teamId}
                  grow
                />
              </div>
              <h3>All time</h3>
            </div>
            <div className={styles.stack}>
              <div className={styles.score}>
                <ScoreDisplay
                  scoreType="performance"
                  subjectType="team"
                  teamId={teamId}
                  fromDate={new Date(
                    new Date().getTime() - 30 * 24 * 3600 * 1000,
                  )
                    .toISOString()
                    .substring(0, 10)}
                  grow
                />
              </div>
              <h3>Past month</h3>
            </div>
          </div>
          <h2>Performance Score</h2>
        </div>
        <div className={styles.card}>
          <div className={styles.row}>
            <div className={styles.stack}>
              <div className={styles.score}>
                <ScoreDisplay
                  scoreType="processAdoption"
                  subjectType="team"
                  teamId={teamId}
                  grow
                />
              </div>
              <h3>All time</h3>
            </div>
            <div className={styles.stack}>
              <div className={styles.score}>
                <ScoreDisplay
                  scoreType="processAdoption"
                  subjectType="team"
                  teamId={teamId}
                  fromDate={new Date(
                    new Date().getTime() - 30 * 24 * 3600 * 1000,
                  )
                    .toISOString()
                    .substring(0, 10)}
                  grow
                />
              </div>
              <h3>Past month</h3>
            </div>
          </div>
          <h2>Process Adoption Score</h2>
        </div>
      </div>
      <Table
        rows={userData}
        keyColumn="cognitoId"
        allowSort
        linkFunction={(id) => `teamMembers/${id}`}
        title={`Team members`}
        rowLinks={{
          fullName: {
            label: 'View User',
            to: (id) => `/administrator/users/${id}`,
          },
        }}
        columns={[
          {
            id: 'firstName',
            label: 'First Name',
            numeric: false,
          },
          {
            id: 'lastName',
            label: 'Last Name',
            numeric: false,
          },
          {
            id: 'isLead',
            label: 'Team Leader',
            boolean: true,
          },
          {
            id: 'performanceScore',
            label: 'Performance (last month)',
            component: true,
          },
          {
            id: 'processAdoptionScore',
            label: 'Process Adoption (last month)',
            component: true,
          },
        ]}
      />
    </div>
  );
}
