import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import styles from './GoalEntry.module.scss';
import ProgressBar from '../../../../../Common/ProgressBar/ProgressBar';
import GoalProgressChart from '../../../../../Common/TreeEditor/Nodes/GoalNode/GoalProgressChart/GoalProgressChart';
import { TeamLogMeasurementDialog } from '../../../../../Common/LogMeasurementDialog/LogMeasurementDialog';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../../../../util/formatDate';
import useAuth from '../../../../../../../hooks/useAuth';

function getDurationProgress(start, end, now) {
  if (now > end) {
    return 1;
  }
  if (now < start) {
    return 0;
  }
  return (now - start) / (end - start);
}

export default function GoalEntry(props) {
  const [isLogMeasurementDialogOpen, setIsLogMeasurementDialogOpen] =
    React.useState(false);
  const { goal, reload } = props;
  const { teamId } = useParams();
  const { currentUser } = useAuth();
  const canUpdateGoal = currentUser.teams.filter((t) => t.id === teamId)?.[0]
    ?.isLead;

  const {
    id,
    label,
    description,
    startValue,
    endValue,
    startTime,
    endTime,
    goalMeasures,
  } = goal;

  const currentValue =
    goalMeasures.length > 0
      ? goalMeasures[goalMeasures.length - 1].value
      : startValue;

  const startTimeMs = new Date(startTime).getTime();
  const endTimeMs = new Date(endTime).getTime();
  const nowMs = new Date().getTime();
  const timeProgress = getDurationProgress(startTimeMs, endTimeMs, nowMs);
  const goalProgress = (currentValue - startValue) / (endValue - startValue);
  const goalProgressDesiredValue = Math.floor(
    timeProgress * (endValue - startValue) + startValue,
  );
  const goalProgressDesired =
    (goalProgressDesiredValue - startValue) / (endValue - startValue);

  return (
    <div className={styles.goalEntry}>
      <TeamLogMeasurementDialog
        goal={goal}
        isOpen={isLogMeasurementDialogOpen}
        close={() => setIsLogMeasurementDialogOpen(false)}
        reload={reload}
      />
      <div className={styles.innerWrapper}>
        <div className={`${styles.topRow}`}>
          <Link to={id}>
            <p className={styles.title}>{label}</p>
          </Link>
        </div>
        <div className={styles.mainRow}>
          <div className={styles.stack}>
            {(description && (
              <p className={styles.description}>{description}</p>
            )) || <p className={styles.noData}>No description for this goal</p>}
          </div>
          <div className={styles.stack}>
            <Tooltip
              title={`Current: ${currentValue}, optimal: ${goalProgressDesiredValue}`}
              className={styles.stack}
            >
              <div className={styles.charts}>
                <p className={styles.label}>Goal Progress:</p>
                <ProgressBar
                  value={goalProgress}
                  color="linear-gradient(90deg, rgb(158 212 131), rgb(146 234 178))"
                  trailingDot
                  trailingDotColor="rgb(105 184 105)"
                />

                <ProgressBar
                  value={goalProgressDesired}
                  color="linear-gradient(90deg, rgb(127 151 230), rgb(176 221 254))"
                  trailingDot
                  trailingDotColor="rgb(103 155 219)"
                />
                <div className={styles.row}>
                  <p className={styles.value}>{startValue}</p>
                  <p className={styles.value}>{endValue}</p>
                </div>
              </div>
            </Tooltip>
            <p className={styles.label}>Time:</p>
            <ProgressBar
              value={timeProgress}
              color="linear-gradient(90deg, rgb(212 141 186), rgb(214 183 255)"
              trailingDot
              trailingDotColor="rgb(170 124 232)"
            />
            <div className={styles.row}>
              <p className={styles.value}>
                {getAbsoluteTimestamp(new Date(startTime))}
              </p>
              <p className={styles.value}>
                {getAbsoluteTimestamp(new Date(endTime))}
              </p>
            </div>
          </div>
          <div className={styles.stack}>
            <GoalProgressChart
              label={label}
              startValue={startValue}
              endValue={endValue}
              startTime={startTime}
              endTime={endTime}
              goalMeasures={goalMeasures}
            />
          </div>
          <div className={styles.stack}>
            <p className={styles.label}>Team{goal.teams.length > 0 && 's'}:</p>
            <div className={styles.row}>
              <p className={styles.teams}>
                {goal.teams.map((t, i, a) => (
                  <React.Fragment key={`goal-${id}${t.id}`}>
                    <span className={styles.teamName}>{t.label}</span>
                    {i !== a.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </p>
            </div>
            <p className={styles.label}>Latest measurement:</p>
            {(goalMeasures.length === 0 && (
              <p className={styles.noData}>Not recorded</p>
            )) || (
              <p className={styles.value}>
                {goalMeasures[goalMeasures.length - 1].value}{' '}
                <span className={styles.valueSecondary}>
                  {getAbsoluteTimestamp(
                    new Date(goalMeasures[goalMeasures.length - 1].time),
                  )}
                </span>
                <span className={styles.valueSecondary}>
                  (
                  {getRelativeTimestamp(
                    new Date(goalMeasures[goalMeasures.length - 1].time),
                  )}
                  )
                </span>
              </p>
            )}
            {canUpdateGoal && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsLogMeasurementDialogOpen(true)}
                startIcon={<AddchartOutlinedIcon />}
              >
                Log Measurement
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
