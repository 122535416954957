export default {
  form: {
    nodeSize: {
      x: 400,
      y: 415,
    },
    get depthFactor() {
      return this.nodeSize.x * this.separation.siblings;
    },
    separation: {
      siblings: 1.25,
      nonSiblings: 1.25,
    },
  },
  view: {
    nodeSize: {
      x: 300,
      y: 410,
    },
    get depthFactor() {
      return this.nodeSize.y * this.separation.siblings;
    },
    separation: {
      siblings: 1.2,
      nonSiblings: 1.3,
    },
  },
  viewCondensed: {
    nodeSize: {
      x: 240,
      y: 240,
    },
    get depthFactor() {
      return this.nodeSize.y * this.separation.siblings;
    },
    separation: {
      siblings: 1.1,
      nonSiblings: 1.2,
    },
  },
};
