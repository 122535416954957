import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import APIClient from '../util/APIClient';
import AuthenticationContext from '../contexts/AuthenticationContext';

Auth.configure({
  region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
});

export default function useAuth() {
  return React.useContext(AuthenticationContext);
}

export function AuthenticationProvider(props) {
  const { children } = props;
  const [authState, setAuthState] = React.useState({});

  function updateCurrentAuthenticatedUser() {
    Auth.currentSession()
      .then((currentSession) => {
        const idToken = currentSession?.getIdToken()?.getJwtToken();
        if (!idToken) {
          throw new Error('ID Token not found!');
        }
        APIClient.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        APIClient.get('/account', {
          params: {
            includeGroups: true,
            includeTeams: true,
            includeTeamGoals: true,
          },
        }).then((result) => {
          const groups = result.data.groups.map((g) => g.label);
          const userLastVersion = process.env.REACT_APP_DISPLAY_RELEASE_NOTES
            ? '0.0.0'
            : result.data.lastVersion;
          setAuthState({
            idToken: idToken,
            currentUser: result.data,
            isUser: groups.includes('users'),
            isAdministrator: groups.includes('administrators'),
            isSignedIn: true,
            isAuthenticationComplete: true,
            userLastVersion: userLastVersion || null,
          });
        });
      })
      .catch(() => {
        APIClient.defaults.headers.common.Authorization = undefined;
        setAuthState({
          idToken: null,
          currentUser: null,
          isUser: false,
          isAdministrator: false,
          isSignedIn: false,
          isAuthenticationComplete: true,
          userLastVersion: null,
        });
      });
  }

  React.useEffect(() => {
    function onAuthEvent({ payload }) {
      switch (payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          updateCurrentAuthenticatedUser();
          break;
        case 'signOut':
        case 'signIn_failure':
          setAuthState({
            idToken: null,
            currentUser: null,
            isUser: false,
            isAdministrator: false,
            isSignedIn: false,
            isAuthenticationComplete: true,
            userLastVersion: null,
          });
          APIClient.defaults.headers.common.Authorization = undefined;
          break;
      }
    }

    Hub.listen('auth', onAuthEvent);
    updateCurrentAuthenticatedUser();
  }, []);

  return (
    <AuthenticationContext.Provider value={authState}>
      {children}
    </AuthenticationContext.Provider>
  );
}
