import React from 'react';
import { v4 as uuid } from 'uuid';

export default function ScoreSvg(props) {
  const { score: providedScore } = props;
  const score =
    typeof providedScore === 'number' || providedScore === null
      ? providedScore
      : null;
  const svgId = uuid();
  const progressId = `progress-${svgId}`;
  const strokeFillId = `strokeFill-${svgId}`;
  const progressScore = Math.max(Math.min(score * 270, 270), 0);
  const displayScore = (score * 100).toFixed();
  let scoreColor = '#ff3d3d';
  if (score >= 0.25 && score < 0.45) {
    scoreColor = '#ff904b';
  } else if (score >= 0.45 && score < 0.7) {
    scoreColor = '#ffcb4b';
  } else if (score >= 0.7 && score < 0.9) {
    scoreColor = '#0ccd4e';
  } else if (score > 0.9) {
    scoreColor = '#56ff86';
  }

  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={strokeFillId}>
          <stop stopColor={scoreColor} offset="0%" />
          <stop stopColor={scoreColor} offset="100%" />
          {/*<stop stopColor="#ff3d3d" offset="0%" />*/}
          {/*<stop stopColor="#ffd645" offset="30%" />*/}
          {/*<stop stopColor="#ffd645" offset="70%" />*/}
          {/*<stop stopColor="#0ccd4e" offset="100%" />*/}
        </linearGradient>
        <linearGradient id="linearGradientDark" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#000000" stopOpacity="0" />
          <stop offset="50%" stopColor="#000000" stopOpacity="0" />
          <stop offset="100%" stopColor="#000000" stopOpacity=".3" />
        </linearGradient>
        <linearGradient id="linearGradientLight" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#00aaff" stopOpacity="0" />
          <stop offset="50%" stopColor="#00aaff" stopOpacity="0" />
          <stop offset="100%" stopColor="#00aaff" stopOpacity="1" />
        </linearGradient>
        <radialGradient
          id="radialGradient"
          cx="50"
          cy="55"
          r="45"
          fr="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="#000" stopOpacity="0.2" />
          <stop offset="25%" stopColor="#000" stopOpacity="0.0" />
          <stop offset="75%" stopColor="#000" stopOpacity="0.0" />
          <stop offset="100%" stopColor="#000" stopOpacity="0.2" />
        </radialGradient>
        <mask id={progressId}>
          <circle
            cx="50"
            cy="55"
            r="40"
            dx="50"
            fill="none"
            strokeWidth="10"
            stroke="white"
            strokeLinecap="round"
            pathLength="360"
            // strokeDasharray="200 360"
            strokeDasharray={`${progressScore} 360`}
            transform="translate(3.55 8.5) rotate(135)"
            transform-origin="50 50"
          />
        </mask>
      </defs>
      <circle
        id="background"
        cx="50"
        cy="55"
        r="40"
        dx="50"
        fill="none"
        strokeWidth="15"
        stroke="#dadada"
        strokeLinecap="round"
        pathLength="360"
        strokeDasharray="270 90"
        strokeDashoffset="225"
      />
      {score !== null && (
        <>
          <circle
            id="foreground"
            cx="50"
            cy="55"
            r="40"
            dx="50"
            fill="none"
            strokeWidth="20"
            stroke={`url(#${strokeFillId})`}
            strokeLinecap="round"
            pathLength="360"
            strokeDasharray="270 90"
            strokeDashoffset="225"
            mask={`url(#${progressId})`}
          />
          <rect
            width="50"
            height="100"
            fill="url(#linearGradientDark)"
            mask={`url(#${progressId})`}
          />
          <rect
            width="50"
            height="100"
            x="50"
            fill="url(#linearGradientLight)"
            mask={`url(#${progressId})`}
          />
          <rect
            width="100"
            height="100"
            fill="url(#radialGradient)"
            mask={`url(#${progressId})`}
          />
        </>
      )}
      <text
        fontSize={score !== null ? '32px' : '28px'}
        fontWeight="600"
        fontFamily="'Roboto','Helvetica','Arial',sans-serif"
        fill="#666"
        textAnchor="middle"
        x="50"
        y={score !== null ? '68' : '66'}
      >
        {(score !== null && displayScore) || 'N/A'}
        {/*{(score !== null && displayScore) || 'Ø'}*/}
      </text>
    </svg>
  );
}
