import React from 'react';
import styles from './PreparedCommitment.module.scss';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Menu from '../../../Common/Menu/Menu';

export default function PreparedCommitment(props) {
  const { commitment, editCommitment, deleteCommitment } = props;
  const { label, note, startValue, endValue } = commitment;

  return (
    <div className={styles.preparedCommitment}>
      <div className={styles.stack}>
        <div className={styles.row}>
          <p className={styles.label}>{label}</p>
          <p className={styles.valueRange}>
            ({startValue}
            <span className={styles.icon}>
              <EastOutlinedIcon fontSize="inherit" />
            </span>
            {endValue})
          </p>
        </div>
        {note && <p className={styles.note}>{note}</p>}
      </div>
      <Menu
        items={[
          {
            label: `Edit`,
            handler: editCommitment,
          },
          {
            label: `Remove`,
            handler: deleteCommitment,
          },
        ]}
      />
    </div>
  );
}
