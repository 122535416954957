import React from 'react';
import styles from './PastCommitments.module.scss';
import IconButton from '@mui/material/IconButton';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PastCommitmentForm from '../PastCommitmentForm/PastCommitmentForm';
import Tooltip from '@mui/material/Tooltip';

export default React.memo(function PastCommitments(props) {
  const { commitments } = props;
  const [isPastCommitmentsOpen, setIsPastCommitmentsOpen] =
    React.useState(false);
  const toggleIsPastCommitmentsOpen = React.useCallback(() => {
    setIsPastCommitmentsOpen((current) => !current);
  }, []);

  return (
    <div className={styles.pastCommitments}>
      <div
        className={`${styles.pastCommitmentsHeader} ${isPastCommitmentsOpen && commitments.length > 0 ? styles.withBorder : ''}`}
      >
        <h4>Commitments for review </h4>
        <div className={styles.pastCommitmentsHeaderControls}>
          {(commitments.length === 0 && (
            <p className={styles.noCommitments}>no previous commitments</p>
          )) || <p>{commitments.length} commitments</p>}
          {commitments.length > 0 && (
            <Tooltip title="View commitments for review">
              <IconButton
                className={`${styles.dropdownButton} ${isPastCommitmentsOpen ? styles.isOpen : ''}`}
                onClick={toggleIsPastCommitmentsOpen}
              >
                <ExpandMoreOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {isPastCommitmentsOpen && (
        <div className={styles.list}>
          {commitments.map((pastCommitment) => {
            return (
              <PastCommitmentForm
                key={pastCommitment.id}
                commitmentId={pastCommitment.id}
                cognitoId={pastCommitment.cognitoId}
                label={pastCommitment.label}
                note={pastCommitment.note}
                startValue={pastCommitment.startValue}
                endValue={pastCommitment.endValue}
                value={pastCommitment.value}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});
