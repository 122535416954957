import React from 'react';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import useApiData from '../../../../hooks/useApiData';
import Loading from '../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../Common/Error/Error';
import Table, { COMMITMENT_STATUS } from '../../Common/Table/Table/Table';
import UpdateCommitmentDialog from '../../Common/UpdateCommitmentDialog/UpdateCommitmentDialog';

export default function Commitments(props) {
  const { queryType } = props;

  const [commitmentToUpdate, setCommitmentToUpdate] = React.useState(null);
  const [isCommitmentUpdateDialogOpen, setIsCommitmentUpdateDialogOpen] =
    React.useState(false);
  const { data, error, isLoading, reload } = useApiData({
    path: `/user/commitments`,
    params: {
      excludePrepared: true,
      includeGoals: true,
      includeOnlyCurrent: queryType === 'current',
    },
  });

  if (isLoading) {
    return <Loading message="Fetching commitments..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  function getCommitmentStatus(c) {
    if (c.value === c.startValue) {
      return 'NOT_STARTED';
    }
    if (c.value >= c.endValue) {
      return 'COMPLETED';
    }
    if (new Date(c.dueOn).getTime() < new Date().getTime()) {
      return 'EXPIRED';
    }
    return 'IN_PROGRESS';
  }

  const formattedData = data.map((c) => {
    const status = getCommitmentStatus(c);
    return {
      ...c,
      progress: `${c.value} / ${c.endValue} (${Math.floor(((c.value - c.startValue) / (c.endValue - c.startValue)) * 100)}%)`,
      status,
      goalLabel: c.goal?.label,
    };
  });

  const updateCommitmentValueHandler = (row) => {
    setCommitmentToUpdate(row);
    setIsCommitmentUpdateDialogOpen(true);
  };

  return (
    <>
      {commitmentToUpdate !== null && (
        <UpdateCommitmentDialog
          commitment={commitmentToUpdate}
          isOpen={isCommitmentUpdateDialogOpen}
          close={() => {
            setIsCommitmentUpdateDialogOpen(false);
            setCommitmentToUpdate(null);
          }}
          reload={reload}
        />
      )}
      <Table
        rows={formattedData}
        rowFunction={updateCommitmentValueHandler}
        rowFunctionIcon={<AssignmentTurnedInOutlinedIcon />}
        rowFunctionLabel="Update commitment"
        defaultSortKey="label"
        title="Commitments"
        columns={[
          {
            id: 'label',
            label: 'Label',
          },
          {
            id: 'note',
            label: 'Note',
          },
          {
            id: 'goalLabel',
            label: 'Goal',
          },
          {
            id: 'progress',
            label: 'Progress',
          },
          {
            id: 'dueOn',
            label: 'Due on',
            date: true,
          },
          {
            id: 'status',
            label: 'Status',
            enum: COMMITMENT_STATUS,
          },
        ]}
      />
    </>
  );
}
